/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lead from '../components/Common/Lead';
import Accordion from '../components/Common/Accordion';
import Services from '../components/Common/Services';

const Section = styled.section`
  border-bottom: 1px solid rgba(199,87,132,0.25);
  max-width: 900px;
  padding: 0 0 45px;

  &:last-of-type {
    border-bottom: 0;
  }

  figure {
    text-align: center;
  }

  @media only screen and (min-width: 960px) {
    .columns {
      align-items: center;
      grid-gap: 50px;
      grid-template-columns: repeat(2, 1fr);

      &--top {
        align-items: flex-start;
      }
    }
  }
`;

const AccordionData = [
  {
    question: '<p>Jak przygotować monety do wyceny?</p>',
    answer: '<p>Najłatwiej jest zrobić zdjęcie zbiorcze monet awersu i rewersu, nie zmieniając ich położenia. Nie ma potrzeby robić oddzielnych zdjęć dla poszczególnych monet. Tak&nbsp;przygotowane zdjęcia proszę wysłać na <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a>.</p>',
  },
  {
    question: '<p>Czy skupujemy srebrne i złote monety, wyemitowane przez NBP?</p>',
    answer: '<p>Tak.</p>',
  },
  {
    question: '<p>Czy skupujemy srebrne monety bulionowe?</p>',
    answer: '<p>Tak.</p>',
  },
  {
    question: '<p>Czy moneta obiegowa np. 1 złoty 1990 r. może być więcej warta niż nominał?</p>',
    answer: '<p>Tak, pod warunkiem, że <strong>moneta będzie w stanie idealnym</strong> (menniczym). Jeśli&nbsp;moneta jest wyjęta z&nbsp;obiegu i&nbsp;posiada ślady zużycia (wytarte elementy na najwyższych partiach monety), wtedy taka moneta będzie miała jedynie wartość nominału.</p>',
  },
];

const siteMetadata = {
  title: 'Skup starych monet, skup monet obiegowych, sklep numizmatyczny Olsztyn',
  description: 'U nas bezpłatnie wycenisz stare monety. Chętnie skupujemy monety obiegowe, monety srebrne, złote monety historyczne, monety próbne, monety kolekcjonerskie, monety zagraniczne.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/skup-monet-olsztyn/',
  locale: 'pl_PL',
};

const CoinsPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <Section>
      <h1>Skup i wycena starych monet - Olsztyn</h1>

      <Lead
        maxWidth="740px"
        desc="Wartość starych monet zależy od różnych czynników. Kluczowym aspektem jest rzadkość danego typu monety. Rzadkie egzemplarze często znajdują się w starych kolekcjach, które były budowane latami."
      />

      <p>Kolejnym ważnym czynnikiem jest stan zachowania monety. Monety, które przetrwały dziesięciolecia w doskonałym stanie, mogą osiągać znacznie wyższe ceny, niż te same monety, w gorszym stanie. Ocena stanu monety jest szczegółowa i uwzględnia takie aspekty jak zużycie, ślady użytkowania, obecność patyny oraz ewentualne uszkodzenia.</p>

      <p>Kolekcjonerzy, poszukując brakujących elementów do swoich zbiorów, są skłonni zapłacić wysokie sumy za unikalne i rzadkie monety.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.proba_polonia)}
          alt="złota moneta - 20 złotych 1925"
        />
        <figcaption><strong>20 złotych 1925</strong> - próbna moneta, wybita w złocie, nakład zaledwie 5&nbsp;sztuk.<br />W 2020 roku podczas aukcji moneta została sprzedana&nbsp;za&nbsp;<strong>318.000&nbsp;zł</strong><br /> (źródło: coinstrail.com)</figcaption>
      </figure>

      <h2>Gdzie sprzedać srebrne lub złote monety?</h2>

      <p>Wiele osób zastanawia się, gdzie można sprzedać swoje srebrne lub złote monety, aby uzyskać za nie jak najlepszą cenę. Skup monet to idealne miejsce, gdzie można dokonać takiej transakcji. Profesjonalne firmy zajmujące się skupem i wyceną monet oferują rzetelną i uczciwą wycenę, bazującą na aktualnych cenach rynkowych oraz indywidualnych cechach poszczególnych monet.</p>

      <p>Można udać się do specjalistycznych sklepów numizmatycznych, które nie tylko dokonują wyceny, ale również prowadzą skup monet, często oferując konkurencyjne stawki. Alternatywnie, sprzedaż monet można zrealizować poprzez aukcje internetowe lub domy aukcyjne specjalizujące się w numizmatyce, co może umożliwić dotarcie do szerszego grona potencjalnych kupców.</p>

      <h3>Skupujemy głównie:</h3>

      <ul>
        <li>monety z okresu międzywojennego - 2 RP</li>
        <li>monety Wolnego Miasta Gdańsk</li>
        <li>monety srebrne</li>
        <li>monety złote</li>
        <li>monety w gradingu NGC i PCGS</li>
        <li>monety próbne</li>
        <li>monety Getta Łódź</li>
        <li>monety wojskowe</li>
      </ul>

      <h3>Interesują nas również:</h3>

      <ul>
        <li>monety PRL</li>
        <li>polskie monety obiegowe (1990-2000)</li>
        <li>monety kolekcjonerskie emitowane przez NBP</li>
        <li>monety 2 złote Nordic Gold</li>
        <li>monety rosyjskie</li>
        <li>monety niemieckie</li>
        <li>inne monety zagraniczne</li>
      </ul>

      <h2>Skup monet na odległość</h2>

      <p>Działamy głównie na rynku lokalnym w Olsztynie i okolicach. Ale jest również możliwość wysłania do nas monet. W&nbsp;tym celu proszę odezwać się do nas na <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a> lub przez aplikację <strong>Messenger</strong> lub <strong>WhatsApp</strong>. Taka forma współpracy pozwala na wygodną i bezpieczną transakcję bez konieczności osobistej wizyty.</p>

      <p>Proces wyceny i skupu na odległość jest prosty i transparentny – po przesłaniu zdjęć i opisu monet, wstępnie wyceniamy wartość numizmatów, a po otrzymaniu monet dokonujemy ostatecznej analizy i proponujemy cenę. Jeśli klient zaakceptuje ofertę, przelewamy pieniądze bezpośrednio na wskazane konto bankowe. Taka usługa jest szczególnie przydatna dla osób, które mają ograniczony czas na osobiste wizyty.</p>
    </Section>

    <Section>
      <h2>Monety II RP</h2>

      <p>Po odzyskaniu niepodległości w 1918 roku, Polska stanęła przed wyzwaniem stworzenia własnego systemu walutowego. Początkowo w obiegu były monety z czasów zaborów: rosyjskie ruble, niemieckie marki oraz austriackie korony.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.srebrne_monety)}
          alt="srebrne monety 2 RP"
        />
        <figcaption>Polskie monety obiegowe z okresu międzywojennego</figcaption>
      </figure>

      <p>W 1924 roku, po reformie walutowej przeprowadzonej przez Władysława Grabskiego, wprowadzono nową walutę – złoty, który zastąpił dotychczasową markę polską. Dowiedz się więcej o <Link to="/skup-monet-ii-rp/">monetach 2 RP</Link>.</p>

    </Section>

    <Section style={{ display: 'none' }}>
      <h2>Monety kolekcjonerskie</h2>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>

      <h3>Monety próbne</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>

      <h3>Monety okolicznościowe 2 złote Nordic Gold</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>

      <h3>Monety w gradingu NGC i PCGS</h3>

      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis quia laborum incidunt qui rerum aperiam officia ut esse nostrum error impedit amet, minima est numquam atque? Sapiente perspiciatis doloribus dignissimos harum voluptatem et ea labore autem quae quidem recusandae aut voluptates veritatis praesentium ducimus maiores non unde voluptas consequatur, esse dolor modi?</p>

      <p>Eius amet quidem, nulla doloremque ex fugiat accusantium. Quasi perferendis aut aliquam pariatur? Incidunt eos velit voluptatum explicabo assumenda, laborum a? Aut corporis corrupti magni! Quae nisi obcaecati aliquid eum repellat! Pariatur iste, natus libero quisquam deleniti inventore laborum sequi laboriosam rem recusandae accusantium molestiae aut maiores dolorem.</p>

      <h3>Monety z okresu PRL</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>
    </Section>

    <Section style={{ display: 'none' }}>
      <h2>Monety zagraniczne</h2>

      <h3>Monety rosyjskie</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>

      <h3>Monety niemieckie</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>

      <h3>Inne monety zagraniczne</h3>

      <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas, facere obcaecati expedita quidem commodi possimus repellat aspernatur illum eveniet sint vero quaerat vitae deserunt consequatur aperiam nam numquam voluptate laborum.</p>
    </Section>

    <section className="section-text section-text--no-border">
      <h2>Faq</h2>
      <Accordion data={AccordionData} />
    </section>

    <Services />
  </>
);

export const query = graphql`
  query {
    proba_polonia: file(relativePath: {regex: "/20-zlotych-1925-proba.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
    srebrne_monety: file(relativePath: {regex: "/srebrne-monety-obiegowe.png/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
  }
`;

export default CoinsPage;
